// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "user-service\/register-intent-success": {
        "original_route": "eu\/user-service\/register-intent-success",
        "route": "eu\/user-service\/register-intent-success",
        "params": []
    },
    "keycloak\/contains": {
        "original_route": "eu\/keycloak\/contains",
        "route": "eu\/keycloak\/contains",
        "params": []
    },
    "user\/account\/request-password-reset-for-social-network": {
        "original_route": "eu\/social-network-access\/<socialNetwork>",
        "route": "eu\/social-network-access\/:socialNetwork",
        "params": [
            "socialNetwork"
        ]
    },
    "site\/error": {
        "original_route": "eu\/error",
        "route": "eu\/error",
        "params": []
    },
    "ser-fine\/show-detail": {
        "original_route": "GET eu\/izan\/salaketa\/<idSERFine:\\w+>\/xehetasun",
        "route": "eu\/izan\/salaketa\/:idSERFine\/xehetasun",
        "params": [
            "idSERFine"
        ]
    },
    "user\/user\/create": {
        "original_route": "POST eu\/erabiltzailea",
        "route": "eu\/erabiltzailea",
        "params": []
    },
    "user\/user\/update": {
        "original_route": "PUT eu\/erabiltzailea\/<id:\\d+>",
        "route": "eu\/erabiltzailea\/:id",
        "params": [
            "id"
        ]
    },
    "user\/user\/update-basic-location": {
        "original_route": "PUT eu\/user-basic\/<id:\\d+>",
        "route": "eu\/user-basic\/:id",
        "params": [
            "id"
        ]
    },
    "account\/logout": {
        "original_route": "eu\/deskonektatu",
        "route": "eu\/deskonektatu",
        "params": []
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT eu\/erabiltzailearen-baja",
        "route": "eu\/erabiltzailearen-baja",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "eu\/erabiltzailearen-baja\/<token>",
        "route": "eu\/erabiltzailearen-baja\/:token",
        "params": [
            "token"
        ]
    },
    "account\/edit": {
        "original_route": "eu\/nire-kontua",
        "route": "eu\/nire-kontua",
        "params": []
    },
    "account\/user": {
        "original_route": "eu\/nire-datuak",
        "route": "eu\/nire-datuak",
        "params": []
    },
    "account\/security": {
        "original_route": "eu\/segurtasuna",
        "route": "eu\/segurtasuna",
        "params": []
    },
    "user\/account\/social-login": {
        "original_route": "eu\/account\/social-login\/<social_network_name>",
        "route": "eu\/account\/social-login\/:social_network_name",
        "params": [
            "social_network_name"
        ]
    },
    "glovie\/vehicles": {
        "original_route": "eu\/nire-ibilgailuak",
        "route": "eu\/nire-ibilgailuak",
        "params": []
    },
    "glovie\/vehicles-page": {
        "original_route": "eu\/nire-ibilgailuak\/<page:\\d+>",
        "route": "eu\/nire-ibilgailuak\/:page",
        "params": [
            "page"
        ]
    },
    "glovie\/trailers": {
        "original_route": "eu\/nire-atoiak",
        "route": "eu\/nire-atoiak",
        "params": []
    },
    "glovie\/trailers-page": {
        "original_route": "eu\/nire-atoiak\/<page:\\d+>",
        "route": "eu\/nire-atoiak\/:page",
        "params": [
            "page"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST eu\/pasahitza-berreskuratu",
        "route": "eu\/pasahitza-berreskuratu",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "eu\/pasahitza-berreskuratu",
        "route": "eu\/pasahitza-berreskuratu",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST eu\/pasahitza-aldatu\/<token>",
        "route": "eu\/pasahitza-aldatu\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "eu\/pasahitza-aldatu\/<token>",
        "route": "eu\/pasahitza-aldatu\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/email-update-request": {
        "original_route": "eu\/posta-elektronikoaren-helbide-aldaketa-eskatu",
        "route": "eu\/posta-elektronikoaren-helbide-aldaketa-eskatu",
        "params": []
    },
    "account\/update-email": {
        "original_route": "eu\/posta-elektronikoaren-helbidea-aldatu\/<token>\/<newEmail>",
        "route": "eu\/posta-elektronikoaren-helbidea-aldatu\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "eu\/berretsi-aldaketa-email\/<code>",
        "route": "eu\/berretsi-aldaketa-email\/:code",
        "params": [
            "code"
        ]
    },
    "account\/login": {
        "original_route": "eu\/konektatu",
        "route": "eu\/konektatu",
        "params": []
    },
    "account\/end-process": {
        "original_route": "eu\/end-process",
        "route": "eu\/end-process",
        "params": []
    },
    "account\/register": {
        "original_route": "eu\/erregistroa",
        "route": "eu\/erregistroa",
        "params": []
    },
    "user\/user\/change-password": {
        "original_route": "eu\/pasahitza-aldatu",
        "route": "eu\/pasahitza-aldatu",
        "params": []
    },
    "site\/terms": {
        "original_route": "eu\/baldintza-legalak",
        "route": "eu\/baldintza-legalak",
        "params": []
    },
    "site\/terms-cookies": {
        "original_route": "eu\/pribatutasun-politika-cookies",
        "route": "eu\/pribatutasun-politika-cookies",
        "params": []
    },
    "site\/transparency": {
        "original_route": "eu\/gardentasuna",
        "route": "eu\/gardentasuna",
        "params": []
    },
    "account\/validate": {
        "original_route": "eu\/berretsi\/<token>",
        "route": "eu\/berretsi\/:token",
        "params": [
            "token"
        ]
    },
    "glovie\/pagatelia-order": {
        "original_route": "eu\/ordena\/viat\/<id:\\d+>",
        "route": "eu\/ordena\/viat\/:id",
        "params": [
            "id"
        ]
    },
    "order\/list": {
        "original_route": "eu\/aginduak",
        "route": "eu\/aginduak",
        "params": []
    },
    "order\/page": {
        "original_route": "eu\/aginduak\/<page:\\d+>",
        "route": "eu\/aginduak\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/list": {
        "original_route": "eu\/aginduak\/viat",
        "route": "eu\/aginduak\/viat",
        "params": []
    },
    "mobe\/page": {
        "original_route": "eu\/aginduak\/viat\/<page:\\d+>",
        "route": "eu\/aginduak\/viat\/:page",
        "params": [
            "page"
        ]
    },
    "toll\/list": {
        "original_route": "eu\/aginduak\/bidesariak",
        "route": "eu\/aginduak\/bidesariak",
        "params": []
    },
    "toll\/page": {
        "original_route": "eu\/aginduak\/bidesariak\/<page:\\d+>",
        "route": "eu\/aginduak\/bidesariak\/:page",
        "params": [
            "page"
        ]
    },
    "mobe\/update-vehicle": {
        "original_route": "PUT eu\/mobe\/<id:\\w*>\/vehicle\/<vehicleId:\\d+>",
        "route": "eu\/mobe\/:id\/vehicle\/:vehicleId",
        "params": [
            "id",
            "vehicleId"
        ]
    },
    "mobe\/unbind-vehicle": {
        "original_route": "PUT eu\/mobe\/<id:\\w*>\/erori-ibilgailua",
        "route": "eu\/mobe\/:id\/erori-ibilgailua",
        "params": [
            "id"
        ]
    },
    "mobe\/unassign": {
        "original_route": "PUT eu\/mobe\/<id:\\w*>\/erori",
        "route": "eu\/mobe\/:id\/erori",
        "params": [
            "id"
        ]
    },
    "mobe\/search": {
        "original_route": "GET eu\/mobe\/bilaketa",
        "route": "eu\/mobe\/bilaketa",
        "params": []
    },
    "mobe\/update-wallet": {
        "original_route": "PUT eu\/mobe\/<idMobe:\\w+>\/wallet\/<idWallet:\\d+>",
        "route": "eu\/mobe\/:idMobe\/wallet\/:idWallet",
        "params": [
            "idMobe",
            "idWallet"
        ]
    },
    "site\/landing-ora": {
        "original_route": "GET eu\/araututako-aparkalekuaren-ordainketa-mugikorrarekin",
        "route": "eu\/araututako-aparkalekuaren-ordainketa-mugikorrarekin",
        "params": []
    },
    "ser-ticket\/show-detail": {
        "original_route": "GET eu\/tiketa\/<idSERTicket:\\w+>\/detail",
        "route": "eu\/tiketa\/:idSERTicket\/detail",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/send-email": {
        "original_route": "GET eu\/tiketa\/<idSERTicket:\\w+>\/bidali-posta-elektronikoa",
        "route": "eu\/tiketa\/:idSERTicket\/bidali-posta-elektronikoa",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET eu\/tiketa\/<idSERTicket:\\w+>.pdf",
        "route": "eu\/tiketa\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "ser-ticket\/pay": {
        "original_route": "eu\/aparkatu-<slug>\/hartu-tiketa",
        "route": "eu\/aparkatu-:slug\/hartu-tiketa",
        "params": [
            "slug"
        ]
    },
    "ser-ticket\/create": {
        "original_route": "POST eu\/tiketa-izan\/hartu-tiketa",
        "route": "eu\/tiketa-izan\/hartu-tiketa",
        "params": []
    },
    "ser-ticket\/unpark": {
        "original_route": "POST eu\/tiketa\/<id>\/desaparkatu",
        "route": "eu\/tiketa\/:id\/desaparkatu",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/check-unpark": {
        "original_route": "GET eu\/tiketa\/<id>\/desaparkatu",
        "route": "eu\/tiketa\/:id\/desaparkatu",
        "params": [
            "id"
        ]
    },
    "ser-ticket\/new": {
        "original_route": "eu\/tiketa\/<id>\/berria",
        "route": "eu\/tiketa\/:id\/berria",
        "params": [
            "id"
        ]
    },
    "wallet\/payment-method": {
        "original_route": "eu\/diru-zorroa\/ordainketa-modua",
        "route": "eu\/diru-zorroa\/ordainketa-modua",
        "params": []
    },
    "wallet\/my-wallet": {
        "original_route": "eu\/diru-zorroa",
        "route": "eu\/diru-zorroa",
        "params": []
    },
    "wallet\/show": {
        "original_route": "eu\/diru-zorroa\/<idWallet:\\d+>\/ikusi",
        "route": "eu\/diru-zorroa\/:idWallet\/ikusi",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/data": {
        "original_route": "eu\/diru-zorroa\/<idWallet:\\d+>\/datuak",
        "route": "eu\/diru-zorroa\/:idWallet\/datuak",
        "params": [
            "idWallet"
        ]
    },
    "wallet\/beneficiaries": {
        "original_route": "eu\/diru-zorroa\/<idWallet:\\d+>\/onuradunak",
        "route": "eu\/diru-zorroa\/:idWallet\/onuradunak",
        "params": [
            "idWallet"
        ]
    },
    "glovie\/wallet": {
        "original_route": "eu\/nire-zorroak",
        "route": "eu\/nire-zorroak",
        "params": []
    },
    "wallet\/pay-debt": {
        "original_route": "PUT eu\/diru-zorroa\/zorra",
        "route": "eu\/diru-zorroa\/zorra",
        "params": []
    },
    "wallet\/create-deposit": {
        "original_route": "POST eu\/diru-zorroa\/kargatu",
        "route": "eu\/diru-zorroa\/kargatu",
        "params": []
    },
    "wallet\/confirm-payment-intent": {
        "original_route": "POST eu\/diru-zorroa\/confirm-payment-intent",
        "route": "eu\/diru-zorroa\/confirm-payment-intent",
        "params": []
    },
    "wallet\/associate-credit-card": {
        "original_route": "POST eu\/diru-zorroa\/<idWallet:\\d+>\/txartela-elkartua\/<idCreditCard:\\d+>",
        "route": "eu\/diru-zorroa\/:idWallet\/txartela-elkartua\/:idCreditCard",
        "params": [
            "idWallet",
            "idCreditCard"
        ]
    },
    "invoice\/pdf": {
        "original_route": "GET eu\/fakturazioa\/fakturak\/<invoiceUid>.pdf",
        "route": "eu\/fakturazioa\/fakturak\/:invoiceUid.pdf",
        "params": [
            "invoiceUid"
        ]
    },
    "invoice\/list": {
        "original_route": "GET eu\/fakturazioa\/fakturak",
        "route": "eu\/fakturazioa\/fakturak",
        "params": []
    },
    "invoice\/page": {
        "original_route": "GET eu\/fakturazioa\/fakturak\/<page:\\d+>",
        "route": "eu\/fakturazioa\/fakturak\/:page",
        "params": [
            "page"
        ]
    },
    "invoice\/update": {
        "original_route": "PUT eu\/fakturazioa\/datuak",
        "route": "eu\/fakturazioa\/datuak",
        "params": []
    },
    "parking\/list": {
        "original_route": "GET eu\/aparkalekua-bilatu",
        "route": "eu\/aparkalekua-bilatu",
        "params": []
    },
    "parking\/list-aeroparking": {
        "original_route": "GET eu\/aparkalekua-bilatu-aireportu",
        "route": "eu\/aparkalekua-bilatu-aireportu",
        "params": []
    },
    "parking\/list-season-ticket": {
        "original_route": "GET eu\/abonamendua-bilatu",
        "route": "eu\/abonamendua-bilatu",
        "params": []
    },
    "parking\/search-season-ticket": {
        "original_route": "POST eu\/abonamendua-bilatu",
        "route": "eu\/abonamendua-bilatu",
        "params": []
    },
    "booking\/process": {
        "original_route": "GET eu\/baieztatu-erreserba\/<idPrebooking:\\d+>",
        "route": "eu\/baieztatu-erreserba\/:idPrebooking",
        "params": [
            "idPrebooking"
        ]
    },
    "vehicle-inspection-booking\/process": {
        "original_route": "GET eu\/baieztatu-erreserba-iat",
        "route": "eu\/baieztatu-erreserba-iat",
        "params": []
    },
    "glovie\/list-actives": {
        "original_route": "GET eu\/guantera\/actuales",
        "route": "eu\/guantera\/actuales",
        "params": []
    },
    "glovie\/list-inactives": {
        "original_route": "GET eu\/guantera\/pasados\/<page:\\d+>",
        "route": "eu\/guantera\/pasados\/:page",
        "params": [
            "page"
        ]
    },
    "booking\/show": {
        "original_route": "GET eu\/erreserba\/<idBooking:\\d+>\/ikusi",
        "route": "eu\/erreserba\/:idBooking\/ikusi",
        "params": [
            "idBooking"
        ]
    },
    "booking\/new": {
        "original_route": "GET eu\/erreserba\/<idBooking:\\d+>\/berria",
        "route": "eu\/erreserba\/:idBooking\/berria",
        "params": [
            "idBooking"
        ]
    },
    "booking\/cancel": {
        "original_route": "PUT eu\/erreserba\/ezeztatu\/<idBooking:\\d+>",
        "route": "eu\/erreserba\/ezeztatu\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "vehicle-brand\/vehicle-models": {
        "original_route": "GET eu\/ibilgailu-marka\/<brandId:\\d+>\/modeloak",
        "route": "eu\/ibilgailu-marka\/:brandId\/modeloak",
        "params": [
            "brandId"
        ]
    },
    "vehicle\/index": {
        "original_route": "GET eu\/ibilgailua",
        "route": "eu\/ibilgailua",
        "params": []
    },
    "vehicle\/view": {
        "original_route": "GET eu\/ibilgailua\/<vehicleId:\\d+>",
        "route": "eu\/ibilgailua\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/delete": {
        "original_route": "DELETE eu\/ibilgailua\/<vehicleId:\\d+>",
        "route": "eu\/ibilgailua\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "vehicle\/create": {
        "original_route": "POST eu\/ibilgailua",
        "route": "eu\/ibilgailua",
        "params": []
    },
    "vehicle\/update": {
        "original_route": "PUT eu\/ibilgailua\/<vehicleId:\\d+>",
        "route": "eu\/ibilgailua\/:vehicleId",
        "params": [
            "vehicleId"
        ]
    },
    "season-ticket\/contract": {
        "original_route": "GET eu\/abonamendua\/<seasonTicketId:\\d+>\/kontratatu",
        "route": "eu\/abonamendua\/:seasonTicketId\/kontratatu",
        "params": [
            "seasonTicketId"
        ]
    },
    "season-ticket-contract\/show-detail": {
        "original_route": "GET eu\/kontratuak\/abonamendua\/<id:\\d+>\/xehetasun",
        "route": "eu\/kontratuak\/abonamendua\/:id\/xehetasun",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/show": {
        "original_route": "GET eu\/kontratuak\/abonamendua\/<id:\\d+>\/ikusi",
        "route": "eu\/kontratuak\/abonamendua\/:id\/ikusi",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/new": {
        "original_route": "GET eu\/kontratuak\/abonamendua\/<id:\\d+>\/berria",
        "route": "eu\/kontratuak\/abonamendua\/:id\/berria",
        "params": [
            "id"
        ]
    },
    "season-ticket-contract\/create": {
        "original_route": "POST eu\/abonamendua",
        "route": "eu\/abonamendua",
        "params": []
    },
    "season-ticket-contract\/update-vehicle": {
        "original_route": "PUT eu\/abonamendua\/<contractId:\\d+>\/ibilgailua\/<vehicleId:\\d+>",
        "route": "eu\/abonamendua\/:contractId\/ibilgailua\/:vehicleId",
        "params": [
            "contractId",
            "vehicleId"
        ]
    },
    "site\/operators": {
        "original_route": "eu\/operadoreak",
        "route": "eu\/operadoreak",
        "params": []
    },
    "glovie\/preferences": {
        "original_route": "GET eu\/lehentasunak",
        "route": "eu\/lehentasunak",
        "params": []
    },
    "user-preferences\/update": {
        "original_route": "PUT eu\/lehentasunak\/<id:\\d+>",
        "route": "eu\/lehentasunak\/:id",
        "params": [
            "id"
        ]
    },
    "wallet-preferences\/index": {
        "original_route": "GET eu\/lehentasunak-diru-zorroa",
        "route": "eu\/lehentasunak-diru-zorroa",
        "params": []
    },
    "wallet-preferences\/update": {
        "original_route": "PUT eu\/lehentasunak-diru-zorroa\/<id:\\d+>",
        "route": "eu\/lehentasunak-diru-zorroa\/:id",
        "params": [
            "id"
        ]
    },
    "glovie\/privacy": {
        "original_route": "GET eu\/pribatutasun",
        "route": "eu\/pribatutasun",
        "params": []
    },
    "user\/privacy\/create-update": {
        "original_route": "PUT eu\/pribatutasun",
        "route": "eu\/pribatutasun",
        "params": []
    },
    "shop\/shop\/invoice": {
        "original_route": "eu\/fakturak\/<uid>",
        "route": "eu\/fakturak\/:uid",
        "params": [
            "uid"
        ]
    },
    "user\/account\/unsubscribe-from-commercials": {
        "original_route": "eu\/agurtze-iragarki\/<email>",
        "route": "eu\/agurtze-iragarki\/:email",
        "params": [
            "email"
        ]
    },
    "viat-shop\/detail": {
        "original_route": "eu\/denda\/ordena\/<id>",
        "route": "eu\/denda\/ordena\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/redirect": {
        "original_route": "eu\/denda\/ekoizteko",
        "route": "eu\/denda\/ekoizteko",
        "params": []
    },
    "viat-shop\/shop": {
        "original_route": "GET eu\/denda\/agindua-berresteko\/<id>",
        "route": "eu\/denda\/agindua-berresteko\/:id",
        "params": [
            "id"
        ]
    },
    "viat-shop\/new": {
        "original_route": "GET eu\/denda\/agindua\/<id>\/agindua",
        "route": "eu\/denda\/agindua\/:id\/agindua",
        "params": [
            "id"
        ]
    },
    "user-preferences\/auto-deposit": {
        "original_route": "eu\/auto-kargatu",
        "route": "eu\/auto-kargatu",
        "params": []
    },
    "workshop-booking\/new": {
        "original_route": "eu\/erreserba-tailerra\/<idBooking:\\d+>\/berria",
        "route": "eu\/erreserba-tailerra\/:idBooking\/berria",
        "params": [
            "idBooking"
        ]
    },
    "workshop-booking\/selection": {
        "original_route": "eu\/liburu-tailerra\/<idProduct:\\d+>",
        "route": "eu\/liburu-tailerra\/:idProduct",
        "params": [
            "idProduct"
        ]
    },
    "workshop-booking\/process": {
        "original_route": "eu\/baieztatu-erreserba-tailerra\/<idBooking:\\d+>",
        "route": "eu\/baieztatu-erreserba-tailerra\/:idBooking",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-workshop-booking": {
        "original_route": "eu\/liburu-tailerra\/<idWorkshopBooking:\\d+>\/ikusi",
        "route": "eu\/liburu-tailerra\/:idWorkshopBooking\/ikusi",
        "params": [
            "idWorkshopBooking"
        ]
    },
    "site\/landing-workshop": {
        "original_route": "eu\/tailerrak",
        "route": "eu\/tailerrak",
        "params": []
    },
    "site\/viat": {
        "original_route": "eu\/viat",
        "route": "eu\/viat",
        "params": []
    },
    "site\/viat-parkings": {
        "original_route": "eu\/viat\/parkings",
        "route": "eu\/viat\/parkings",
        "params": []
    },
    "site\/viat-discounts": {
        "original_route": "eu\/viat\/beherapenak",
        "route": "eu\/viat\/beherapenak",
        "params": []
    },
    "site\/viat-portugal": {
        "original_route": "eu\/viat\/portugalgo-errepublika",
        "route": "eu\/viat\/portugalgo-errepublika",
        "params": []
    },
    "site\/viat-how-it-works": {
        "original_route": "eu\/viat\/funtzionamendua",
        "route": "eu\/viat\/funtzionamendua",
        "params": []
    },
    "site\/landing-how-to-park": {
        "original_route": "eu\/nola-duzu-aparkatu",
        "route": "eu\/nola-duzu-aparkatu",
        "params": []
    },
    "site\/landing-how-to-park-airport-parkings": {
        "original_route": "eu\/aparkatu-aireportu",
        "route": "eu\/aparkatu-aireportu",
        "params": []
    },
    "site\/landing-parking-sharing": {
        "original_route": "eu\/alokairuan-garage",
        "route": "eu\/alokairuan-garage",
        "params": []
    },
    "site\/landing-merchant-how-it-works": {
        "original_route": "eu\/libratu-kupoiak",
        "route": "eu\/libratu-kupoiak",
        "params": []
    },
    "site\/landing-merchant": {
        "original_route": "eu\/merkantzia",
        "route": "eu\/merkantzia",
        "params": []
    },
    "airport\/show": {
        "original_route": "GET eu\/aparkatu-aireportu-<slug>\/<id:\\d+>",
        "route": "eu\/aparkatu-aireportu-:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "town\/show": {
        "original_route": "GET eu\/aparkatu-<slug>",
        "route": "eu\/aparkatu-:slug",
        "params": [
            "slug"
        ]
    },
    "ser-zone\/show": {
        "original_route": "GET eu\/zona\/<zoneId>\/ibilgailua\/<vehicleId:\\d+>",
        "route": "eu\/zona\/:zoneId\/ibilgailua\/:vehicleId",
        "params": [
            "zoneId",
            "vehicleId"
        ]
    },
    "glovie\/fines": {
        "original_route": "GET eu\/salaketa\/ezeztatu",
        "route": "eu\/salaketa\/ezeztatu",
        "params": []
    },
    "ser-fine\/check": {
        "original_route": "POST eu\/salaketa\/check",
        "route": "eu\/salaketa\/check",
        "params": []
    },
    "vehicle-inspection-station\/show": {
        "original_route": "eu\/iat\/<slug>\/<id:\\d+>",
        "route": "eu\/iat\/:slug\/:id",
        "params": [
            "slug",
            "id"
        ]
    },
    "site\/landing-vehicle-inspection": {
        "original_route": "eu\/iat",
        "route": "eu\/iat",
        "params": []
    },
    "vehicle-inspection-station\/list": {
        "original_route": "eu\/bilaketa-iat",
        "route": "eu\/bilaketa-iat",
        "params": []
    },
    "glovie\/show-itv-booking": {
        "original_route": "GET eu\/erreserba-iat\/<idBooking:\\d+>\/ikusi",
        "route": "eu\/erreserba-iat\/:idBooking\/ikusi",
        "params": [
            "idBooking"
        ]
    },
    "glovie\/show-rotation": {
        "original_route": "GET eu\/biraketa\/<idRotation:\\d+>\/ikusi",
        "route": "eu\/biraketa\/:idRotation\/ikusi",
        "params": [
            "idRotation"
        ]
    },
    "glovie\/show-itv-booking-new": {
        "original_route": "GET eu\/erreserba-iat\/<idBooking:\\d+>\/berria",
        "route": "eu\/erreserba-iat\/:idBooking\/berria",
        "params": [
            "idBooking"
        ]
    },
    "parking\/show": {
        "original_route": "GET eu\/<province>\/<slug>\/<idParking:\\d+>",
        "route": "eu\/:province\/:slug\/:idParking",
        "params": [
            "province",
            "slug",
            "idParking"
        ]
    },
    "site\/landing-enterprise": {
        "original_route": "eu\/konpainiak",
        "route": "eu\/konpainiak",
        "params": []
    },
    "site\/landing-electric-charge": {
        "original_route": "eu\/ibilgailu-elektrikoa",
        "route": "eu\/ibilgailu-elektrikoa",
        "params": []
    },
    "merchant\/index": {
        "original_route": "eu\/merkantzia\/konektatu",
        "route": "eu\/merkantzia\/konektatu",
        "params": []
    },
    "merchant\/shop": {
        "original_route": "eu\/merkantzia\/denda",
        "route": "eu\/merkantzia\/denda",
        "params": []
    },
    "merchant\/shop-step": {
        "original_route": "eu\/merkantzia\/denda\/urrats<id:\\d+>",
        "route": "eu\/merkantzia\/denda\/urrats:id",
        "params": [
            "id"
        ]
    },
    "coupon-order\/pdf": {
        "original_route": "eu\/merkantzia\/aginduak\/<id:\\d+>.pdf",
        "route": "eu\/merkantzia\/aginduak\/:id.pdf",
        "params": [
            "id"
        ]
    },
    "merchant\/order": {
        "original_route": "eu\/merkantzia\/aginduak\/<id:\\d+>\/xehetasun",
        "route": "eu\/merkantzia\/aginduak\/:id\/xehetasun",
        "params": [
            "id"
        ]
    },
    "merchant\/sample-coupon": {
        "original_route": "eu\/merchant\/sample-coupon",
        "route": "eu\/merchant\/sample-coupon",
        "params": []
    },
    "wallet\/list": {
        "original_route": "GET eu\/diru-zorroa\/list",
        "route": "eu\/diru-zorroa\/list",
        "params": []
    },
    "booking\/pay-with-wallet": {
        "original_route": "PUT eu\/ordainketa-erreserba\/<idBooking:\\d+>\/diru-zorroa\/<idWallet:\\d+>",
        "route": "eu\/ordainketa-erreserba\/:idBooking\/diru-zorroa\/:idWallet",
        "params": [
            "idBooking",
            "idWallet"
        ]
    },
    "season-ticket-contract\/create-and-pay-with-wallet": {
        "original_route": "POST eu\/ordainketa-abonamendua\/diru-zorroa\/<idWallet:\\d+>",
        "route": "eu\/ordainketa-abonamendua\/diru-zorroa\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-ticket\/create-and-pay-with-wallet": {
        "original_route": "POST eu\/ordainketa-tiketa\/diru-zorroa\/<idWallet:\\d+>",
        "route": "eu\/ordainketa-tiketa\/diru-zorroa\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "ser-fine\/pay-with-wallet": {
        "original_route": "POST eu\/ordainketa-kexa\/diru-zorroa\/<idWallet:\\d+>",
        "route": "eu\/ordainketa-kexa\/diru-zorroa\/:idWallet",
        "params": [
            "idWallet"
        ]
    },
    "kiosk\/reset-password": {
        "original_route": "eu\/kiosko\/pasahitza-berreskuratu\/<token>",
        "route": "eu\/kiosko\/pasahitza-berreskuratu\/:token",
        "params": [
            "token"
        ]
    },
    "on-boarding\/redirect": {
        "original_route": "eu\/on-boarding-ok",
        "route": "eu\/on-boarding-ok",
        "params": []
    },
    "site\/landing-petrol-station": {
        "original_route": "eu\/gasolindegiak",
        "route": "eu\/gasolindegiak",
        "params": []
    },
    "site\/landing-parkings": {
        "original_route": "eu\/parkings",
        "route": "eu\/parkings",
        "params": []
    },
    "poi\/show": {
        "original_route": "eu\/aparkatu-<townSlug>\/parking-<poiSlug>",
        "route": "eu\/aparkatu-:townSlug\/parking-:poiSlug",
        "params": [
            "townSlug",
            "poiSlug"
        ]
    },
    "poi\/show-events": {
        "original_route": "eu\/ekitaldiak-<poiId>\/<month:\\d+>",
        "route": "eu\/ekitaldiak-:poiId\/:month",
        "params": [
            "poiId",
            "month"
        ]
    },
    "poi\/show-vehicle-inspection-poi": {
        "original_route": "eu\/iat\/erreserba-iat-<poiSlug>",
        "route": "eu\/iat\/erreserba-iat-:poiSlug",
        "params": [
            "poiSlug"
        ]
    },
    "offered-parking-pass\/contract": {
        "original_route": "GET eu\/parking\/<idParking:\\d+>\/aparkaleku-bonus\/<id:\\d+>",
        "route": "eu\/parking\/:idParking\/aparkaleku-bonus\/:id",
        "params": [
            "idParking",
            "id"
        ]
    },
    "booked-parking-pass\/new": {
        "original_route": "GET eu\/aparkaleku-bonus\/<id:\\d+>\/berria",
        "route": "eu\/aparkaleku-bonus\/:id\/berria",
        "params": [
            "id"
        ]
    },
    "booked-parking-pass\/show": {
        "original_route": "GET eu\/aparkaleku-bonus\/<id:\\d+>\/ikusi",
        "route": "eu\/aparkaleku-bonus\/:id\/ikusi",
        "params": [
            "id"
        ]
    },
    "mas-movil\/refuse-lot-confirm": {
        "original_route": "POST eu\/masmovil\/ukatu\/<token>\/ok",
        "route": "eu\/masmovil\/ukatu\/:token\/ok",
        "params": [
            "token"
        ]
    },
    "mas-movil\/refuse-lot": {
        "original_route": "eu\/masmovil\/ukatu\/<token>",
        "route": "eu\/masmovil\/ukatu\/:token",
        "params": [
            "token"
        ]
    },
    "charging-point\/list": {
        "original_route": "eu\/bilaketa-ibilgailu-elektrikoa",
        "route": "eu\/bilaketa-ibilgailu-elektrikoa",
        "params": []
    },
    "glovie\/show-charging-point-booking": {
        "original_route": "GET eu\/erreserba-ibilgailu-elektrikoa\/<idBooking:\\d+>\/berria",
        "route": "eu\/erreserba-ibilgailu-elektrikoa\/:idBooking\/berria",
        "params": [
            "idBooking"
        ]
    },
    "driver-plus\/show": {
        "original_route": "eu\/gidari-plus",
        "route": "eu\/gidari-plus",
        "params": []
    },
    "driver-plus\/landing-fines": {
        "original_route": "eu\/gidari-plus\/isunak-ez-jakinarazi",
        "route": "eu\/gidari-plus\/isunak-ez-jakinarazi",
        "params": []
    },
    "driver-plus\/contract": {
        "original_route": "eu\/gidari-plus\/kontratua",
        "route": "eu\/gidari-plus\/kontratua",
        "params": []
    },
    "glovie\/driver-plus-subscriptions": {
        "original_route": "eu\/gidari-plus\/harpidetzak",
        "route": "eu\/gidari-plus\/harpidetzak",
        "params": []
    },
    "glovie\/driver-plus-fines": {
        "original_route": "eu\/gidari-plus\/isunak",
        "route": "eu\/gidari-plus\/isunak",
        "params": []
    },
    "api\/wallet-corporate\/download-file": {
        "original_route": "GET eu\/wallets\/<walletId:\\d+>\/download-movements-csv\/<file>",
        "route": "eu\/wallets\/:walletId\/download-movements-csv\/:file",
        "params": [
            "walletId",
            "file"
        ]
    },
    "wallet-corporate-plates-whitelist\/download": {
        "original_route": "eu\/plates-whitelist\/<walletId:\\d+>\/download",
        "route": "eu\/plates-whitelist\/:walletId\/download",
        "params": [
            "walletId"
        ]
    },
    "rental-car\/show": {
        "original_route": "eu\/autoak-alokatu",
        "route": "eu\/autoak-alokatu",
        "params": []
    },
    "rental-car\/contract": {
        "original_route": "eu\/amaitu-autoen-alokairua",
        "route": "eu\/amaitu-autoen-alokairua",
        "params": []
    },
    "rental-car\/detail": {
        "original_route": "eu\/amaitu-autoen-alokairua\/<id:\\d+>\/bilatu",
        "route": "eu\/amaitu-autoen-alokairua\/:id\/bilatu",
        "params": [
            "id"
        ]
    },
    "rental-car\/search": {
        "original_route": "eu\/amaitu-autoen-alokairua\/xehetasuna",
        "route": "eu\/amaitu-autoen-alokairua\/xehetasuna",
        "params": []
    },
    "rental-car\/un-born": {
        "original_route": "eu\/amaitu-autoen-alokairua\/ikusi\/<unbornId:\\d+>\/<uuid>",
        "route": "eu\/amaitu-autoen-alokairua\/ikusi\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "rental-car\/un-born-share": {
        "original_route": "eu\/amaitu-autoen-alokairua\/share\/<unbornId:\\d+>\/<uuid>",
        "route": "eu\/amaitu-autoen-alokairua\/share\/:unbornId\/:uuid",
        "params": [
            "unbornId",
            "uuid"
        ]
    },
    "site\/index": {
        "original_route": "eu",
        "route": "eu",
        "params": []
    },
    "esp\/sitemap\/lang": {
        "original_route": "eu\/sitemap.xml",
        "route": "eu\/sitemap.xml",
        "params": []
    },
    "site\/home-redirect": {
        "original_route": "esp\/eu",
        "route": "esp\/eu",
        "params": []
    },
    "account\/on-boarding": {
        "original_route": "eu\/profil-osoa",
        "route": "eu\/profil-osoa",
        "params": []
    }
};