// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "cri\/site\/index": {
        "original_route": "cri\/en",
        "route": "cri\/en",
        "params": []
    },
    "site\/error": {
        "original_route": "cri\/en\/error",
        "route": "cri\/en\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "cri\/en\/legal-terms",
        "route": "cri\/en\/legal-terms",
        "params": []
    },
    "cri\/sitemap\/lang": {
        "original_route": "cri\/en\/sitemap.xml",
        "route": "cri\/en\/sitemap.xml",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "cri\/en\/on-boarding-ok",
        "route": "cri\/en\/on-boarding-ok",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "cri\/en\/kiosk\/reset-password\/<token>",
        "route": "cri\/en\/kiosk\/reset-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST cri\/en\/recover-password",
        "route": "cri\/en\/recover-password",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "cri\/en\/recover-password",
        "route": "cri\/en\/recover-password",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST cri\/en\/change-password\/<token>",
        "route": "cri\/en\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "cri\/en\/change-password\/<token>",
        "route": "cri\/en\/change-password\/:token",
        "params": [
            "token"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET cri\/en\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "cri\/en\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "account\/validate": {
        "original_route": "cri\/en\/validate\/<token>",
        "route": "cri\/en\/validate\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT cri\/en\/remove-user",
        "route": "cri\/en\/remove-user",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "cri\/en\/remove-user\/<token>",
        "route": "cri\/en\/remove-user\/:token",
        "params": [
            "token"
        ]
    },
    "account\/update-email": {
        "original_route": "cri\/en\/change-email\/<token>\/<newEmail>",
        "route": "cri\/en\/change-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "cri\/en\/change-email-confirm\/<code>",
        "route": "cri\/en\/change-email-confirm\/:code",
        "params": [
            "code"
        ]
    },
    "account\/end-process": {
        "original_route": "cri\/en\/end-process",
        "route": "cri\/en\/end-process",
        "params": []
    }
};