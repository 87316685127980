// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
import { json as ptBR } from './pt-BR'
import { json as en } from './en'
import { json as eu } from './eu'
import { json as ca } from './ca'
import { json as fr } from './fr'
import { json as pl } from './pl'
import { json as es } from './es'

export const country = {"pt-BR" : ptBR, "en" : en, "eu" : eu, "ca" : ca, "fr" : fr, "pl" : pl, "es" : es}