import { countryRoutes, apiRoutes } from 'commons/js/routes'
import UrlTo from './UrlTo'
import toUrlSearchParams from './toUrlSearchParams'

UrlTo.setRoutes(countryRoutes, apiRoutes)

export {
    UrlTo,
    toUrlSearchParams,
}

export default UrlTo
