// be careful!
// generated file with Yii routes with webpack. Don't update lines here!
export const json = {
    "cri\/site\/index": {
        "original_route": "cri",
        "route": "cri",
        "params": []
    },
    "cri\/site\/home-redirect": {
        "original_route": "cri\/es-cr",
        "route": "cri\/es-cr",
        "params": []
    },
    "cri\/sitemap\/lang": {
        "original_route": "cri\/es-cr\/sitemap.xml",
        "route": "cri\/es-cr\/sitemap.xml",
        "params": []
    },
    "site\/error": {
        "original_route": "cri\/error",
        "route": "cri\/error",
        "params": []
    },
    "site\/terms": {
        "original_route": "cri\/terminos-legales",
        "route": "cri\/terminos-legales",
        "params": []
    },
    "on-boarding\/redirect": {
        "original_route": "cri\/on-boarding-ok",
        "route": "cri\/on-boarding-ok",
        "params": []
    },
    "kiosk\/reset-password": {
        "original_route": "cri\/kiosko\/recuperar-contrasena\/<token>",
        "route": "cri\/kiosko\/recuperar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/password-reset": {
        "original_route": "POST cri\/recuperar-contrasena",
        "route": "cri\/recuperar-contrasena",
        "params": []
    },
    "account\/request-password-reset": {
        "original_route": "cri\/recuperar-contrasena",
        "route": "cri\/recuperar-contrasena",
        "params": []
    },
    "user\/account\/reset-password-update": {
        "original_route": "POST cri\/cambiar-contrasena\/<token>",
        "route": "cri\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/reset-password": {
        "original_route": "cri\/cambiar-contrasena\/<token>",
        "route": "cri\/cambiar-contrasena\/:token",
        "params": [
            "token"
        ]
    },
    "ser-ticket\/download-pdf": {
        "original_route": "GET cri\/ticket\/<idSERTicket:\\w+>.pdf",
        "route": "cri\/ticket\/:idSERTicket.pdf",
        "params": [
            "idSERTicket"
        ]
    },
    "account\/validate": {
        "original_route": "cri\/validar\/<token>",
        "route": "cri\/validar\/:token",
        "params": [
            "token"
        ]
    },
    "user\/account\/request-delete-user": {
        "original_route": "PUT cri\/baja-usuario",
        "route": "cri\/baja-usuario",
        "params": []
    },
    "user\/account\/delete": {
        "original_route": "cri\/baja-usuario\/<token>",
        "route": "cri\/baja-usuario\/:token",
        "params": [
            "token"
        ]
    },
    "account\/update-email": {
        "original_route": "cri\/cambiar-email\/<token>\/<newEmail>",
        "route": "cri\/cambiar-email\/:token\/:newEmail",
        "params": [
            "token",
            "newEmail"
        ]
    },
    "user\/account\/confirm": {
        "original_route": "cri\/confirmar-cambio-email\/<code>",
        "route": "cri\/confirmar-cambio-email\/:code",
        "params": [
            "code"
        ]
    },
    "account\/end-process": {
        "original_route": "cri\/end-process",
        "route": "cri\/end-process",
        "params": []
    }
};